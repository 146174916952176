<script setup lang="ts">
interface Props {
  hideScrollIndicator?: boolean
  scrollIndicatorColor?: string
}

withDefaults(defineProps<Props>(), {
  hideScrollIndicator: false,
  scrollIndicatorColor: 'from-white via-white/75',
})

const rootElement = ref<HTMLDivElement | null>(null)
const isListenerNeeded = ref(false)

onMounted(() => {
  if (!rootElement.value || rootElement.value.offsetWidth - rootElement.value.scrollWidth >= 0) {
    return
  }
  isListenerNeeded.value = true

  useEventListener(rootElement, 'wheel', (e: WheelEvent) => {
    if (!rootElement.value) {
      return
    }
    if (
      !e.deltaY
      || (e.deltaY > 0 && rootElement.value.scrollLeft + rootElement.value.offsetWidth + 1 > rootElement.value.scrollWidth)
      || (e.deltaY < 0 && rootElement.value.scrollLeft <= 0)
    ) {
      return true
    }
    const left = rootElement.value.scrollLeft + e.deltaY
    e.preventDefault()
    rootElement.value.scrollTo({ left })
  }, {
    passive: false,
    capture: false,
  })
})
</script>

<template>
  <div
    ref="rootElement"
    class="flex flex-nowrap scrolling-touch no-scrollbar relative overflow-x-auto"
  >
    <slot />
    <transition
      name="fade"
      appear
    >
      <div
        v-if="!hideScrollIndicator && isListenerNeeded"
        :class="[scrollIndicatorColor]"
        class="sticky bottom-0 right-0 top-0 z-20 !ml-0 flex w-16 shrink-0 items-center justify-end bg-gradient-to-l to-transparent"
      />
    </transition>
  </div>
</template>
